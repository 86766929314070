<template>
  <ListPageContainer :title="title">
    <div class="filters">
      <ItemsListFilter v-if="collectionCategories.length > 0 && !firstLoad"
                       :current-filters="collectionCategories"
                       :active-filter="activeCategoryIndex"
                       @filter="filterByCategory"
      />

      <ItemsListFilter v-if="currentTagsDateSet.length > 0 && collectionType === 'events' && !firstLoad"
                       :current-filters="currentTagsDateSet"
                       :active-filter="activeDateIndex"
                       :pre-selected="currentScheduleFilter"
                       @filter="filterByDate"
      />
    </div>

    <r-row class="px-4" v-if="currentDataSet.length > 0 && !loading && !firstLoad">
      <r-col v-for="item in currentDataSet" :key="item.id" :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <LazyAnnouncement :data="item" @goto="goToItem(item)" />
      </r-col>
    </r-row>

    <NothingFound v-else-if="currentDataSet.length === 0 && !loading"/>

    <ListLoading v-else/>
  </ListPageContainer>
</template>

<script>
import ListLoading from "@/components/Miscellaneous/ListLoading";
import NothingFound from "@/components/Miscellaneous/NothingFound";
import LazyAnnouncement from "@/components/LazyAnnouncement/LazyAnnouncement";
import ListPageContainer from "@/components/Containers/ListPageContainer";
import ItemsListFilter from "@/components/Filters/ItemsListFilter";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "CategoryCollectionView",

  components: {
    ListPageContainer,
    LazyAnnouncement,
    NothingFound,
    ListLoading,
    ItemsListFilter
  },

  computed: {
    ...mapGetters(['GET_COLLECTION', 'GET_COLLECTION_CATEGORIES', 'GET_FILTERED_COLLECTION', 'GET_PANEL']),

    currentDataSet() {
      if (this.isFiltered) {
        return this.GET_FILTERED_COLLECTION;
      }
      return this.GET_COLLECTION;
    },

    currentTagsDateSet() {
      return this.$makeDatesTagsSet();
    },

    collectionType() {
      return this.$route.params.type;
    },

    collectionName() {
      return this.$route.params.collection;
    },

    singleCityId() {
      return {
        cityId: this.GET_SINGLE_CITY ? this.GET_SINGLE_CITY[0].id : ''
      }
    },

    panelCoords() {
      return {
        longitude: this.GET_PANEL?.longitude,
        latitude: this.GET_PANEL?.latitude,
        radius: this.$currentConfig?.placesRadius
      }
    },
  },

  mounted() {
    this.firstLoad = true;

    this.$api.getCategoryCollection(this.collectionType, this.collectionName).then(result => {
      this.title = result.data.title;
      this.categories = result.data.category_ids;
    }).finally(() => {
      this.$store.dispatch('GET_COLLECTION_FROM_SERVER', {
        type: this.collectionType,
        category: this.categories,
        cityId: this.singleCityId.cityId,
        date: this.collectionType === 'events' ? this.filterDates : '',
        longitude: this.collectionType === 'places' ? this.panelCoords.longitude : '',
        latitude: this.collectionType === 'places' ? this.panelCoords.latitude : '',
        radius: this.collectionType === 'places' ? this.panelCoords.radius : ''
      }).finally(() => {
        this.collectionCategories = this.getCollectionCategories(this.categories);
        this.firstLoad = false;
        this.loading = false;
      })
    })
  },

  data() {
    return {
      collectionCategories: [],
      categories: null,
      title: null,
      loading: true,
      firstLoad: true,
      isFiltered: false,

      currentScheduleFilter: 'today',
      activeDateIndex: 0,
      filterDates: [],
      filterCategoryId: null,
      activeCategoryIndex: 0,
    }
  },

  methods: {
    ...mapActions(['GET_COLLECTION_FROM_SERVER', 'GET_FILTERED_COLLECTIONS_FROM_SERVER']),

    getCollectionCategories(ids) {
      const currentCategories = this.GET_COLLECTION_CATEGORIES.filter(({ id }) => ids.includes(id));
      return this.$makeCategoryTagsSet(currentCategories);
    },

    filterByCategory({id, index}) {
      if (this.activeCategoryIndex === index) return;

      this.loading = true;
      this.activeCategoryIndex = index;
      this.filterCategoryId = id;

      if (this.filterDates.length === 0 && this.collectionType === 'events') {
        this.filterDates = [
          this.$moment().format('YYYY-MM-DD'),
          this.$moment().format('YYYY-MM-DD'),
        ];
      }

      if (id === 'all') {
        this.filterCategoryId = this.categories;
      }

      this.$store.dispatch('GET_FILTERED_COLLECTIONS_FROM_SERVER',
          {
            type: this.collectionType,
            category: this.filterCategoryId,
            cityId: this.singleCityId?.cityId,
            date: this.collectionType === 'events' ? this.filterDates : '',
            longitude: this.collectionType === 'places' ? this.panelCoords.longitude : '',
            latitude: this.collectionType === 'places' ? this.panelCoords.latitude : '',
            radius: this.collectionType === 'places' ? this.panelCoords.radius : ''
          }).finally(() => {
        this.loading = false;
        this.isFiltered = true;
      });
    },

    filterByDate({id, index}) {
      if (this.activeDateIndex === index) return;

      const todayDate = this.$moment().format('YYYY-MM-DD');
      const tomorrowDate = this.$moment().add(1, 'days').format('YYYY-MM-DD');

      this.loading = true;
      this.activeDateIndex = index;

      switch(id) {
        case 'today':
          this.filterDates = [
            todayDate,
            todayDate
          ];
          break;
        case 'tomorrow':
          this.filterDates = [
            tomorrowDate,
            tomorrowDate
          ];
          break;
        case 'weekend':
          this.filterDates = [
            this.$moment().isoWeekday(6).format('YYYY-MM-DD'),
            this.$moment().isoWeekday(7).format('YYYY-MM-DD')
          ];
          break;
        case 'month':
          this.filterDates = [
            this.$moment().format('YYYY-MM-DD'),
            this.$moment().endOf('month').format('YYYY-MM-DD')
          ];
          break;
        default:
          this.filterDates = [
            todayDate,
            todayDate
          ];
      }

      this.$store.dispatch('GET_FILTERED_COLLECTIONS_FROM_SERVER',
          {
            type: this.collectionType,
            category: this.filterCategoryId,
            cityId: this.singleCityId?.cityId,
            date: this.$route.params.type === 'events' ? this.filterDates : ''
          }).finally(() => {
        this.loading = false;
        this.isFiltered = true;
      });
    },

    goToItem(item) {
      this.$router.push({ path: `/${this.$route.params.type}/${item.id}` });
    },
  }
}
</script>

<style lang="scss">
.filters {
  margin-bottom: 24px;

  .tags {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
